<template>
  <main-layout>
    <v-dialog v-model="dialog">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="d-flex activity-schedule-creating">
          <h1 v-if="isActive">活動予定登録</h1>
          <h1 v-else>活動履歴登録</h1>
        </div>
        <SelectTime
          :date="dataDateFrom"
          :hours="getHours"
          :minutes="getMinutes"
          :hour="dataHoursSelected"
          :minute="dataMinutesSelected"
          @setDate="(value) => { dataDateFrom = value }"
          @setHour="(value) => { dataHoursSelected = value }"
          @setMinute="(value) => { dataMinutesSelected = value }"
        ></SelectTime>
        <div class="mt-2">
          <h4 class="activity-person">活動担当者</h4>
          <p class="text--title my-username" v-if="currentUserLoginName">
            {{ currentUserLoginName }}
          </p>
          <p class="text--title my-username" v-else>&lt; 自分のユーザー名 ></p>
        </div>

        <div class="mt-2">
          <v-row>
            <v-col :cols="6">
              <template>
                <p class="label">相手</p>
                <v-select
                  dense
                  :items="getEnumParticipantTypeList"
                  v-model="dataParticipantsSelected"
                  multiple
                  class="multi-select"
                  :rules="rules.ruleSelectParticipants"
                  required
                ></v-select>
              </template>
            </v-col>
            <v-col :cols="6">
              <template>
                <p class="label">優先度</p>
                <v-select
                  dense
                  :items="getPriority"
                  item-text="name"
                  item-value="id"
                  v-model="dataPrioritySelected"
                  class="multi-select"
                ></v-select>
              </template>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="6">
              <template>
                <p class="label">手段</p>
                <v-select
                  dense
                  :items="getEnumClientActionMethodList"
                  item-text="name"
                  item-value="id"
                  v-model="dataActionMethodIdSelected"
                  class="multi-select"
                ></v-select>
              </template>
            </v-col>
          </v-row>
        </div>
        <div class="mt-2">
          <h5 class="action">アクション</h5>
          <v-textarea
            outlined
            color="#707070"
            height="110px"
            class="text-area"
            v-model="dataActionSelected"
            :rules="[$rules.checkTextAreaLength()]"
          ></v-textarea>
        </div>
        <div style="margin-top: 30px">
          <div class="float-right">
            <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
              閉じる
            </v-btn>
            <v-btn class="t-btn--prm" @click="save"> 保存 </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </main-layout>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
// import { getHourDiffTwoDates } from '@/constants/functions';
import router from '@/router';
import MainLayout from '@/layouts/MainLayout';
// import moment from 'moment';
import SelectTime from './selectTime.vue';

export default {
  components: {
    MainLayout,
    SelectTime,
  },
  name: 'ActivityScheduleRegistration',
  data() {
    return {
      flagValidate: false,
      dataGroup: [],
      minuteCheck: true,
      // v-date-picker-custom
      menuDateFrom: false,
      menuDateTo: false,
      dateTo: new Date().toISOString().substr(0, 10),
      valid: true,
      rules: {
        ruleSelectParticipants: [
          (v) => !!v || '必須項目です。',
          (v) => !(v && v.length === 0) || '必須項目です。',
        ],
      },
      currentUserLoginName: localStorage.getItem('name'),
    };
  },
  props: {
    visible: Boolean,
    isActive: Boolean,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
    ...mapGetters([
      // Const Enum
      'getEnumParticipantTypeList',
      'getEnumClientActionMethodList',
      // Data call API
      'getClientActionHistory',
      // Select default
      'getActionPlannedAt',
      'getHours',
      'getMinutes',
      'getParticipants',
      'getPriority',
      'getActionMethodId',
      'getAction',
      // Selected
      'getActionPlannedAtSelected',
      'getHoursSelected',
      'getMinutesSelected',
      'getParticipantsSelected',
      'getPrioritySelected',
      'getActionMethodIdSelected',
      'getActionSelected',
      'getDateFromSelected',
    ]),
    // v-model selected
    dataDateFrom: {
      get() {
        return this.getDateFromSelected;
      },
      set(value) {
        this.setDateFromSelected(value);
      },
    },
    dataActionPlannedAtSelected: {
      get() {
        return this.getActionPlannedAtSelected;
      },
      set(value) {
        this.setActionPlannedAtSelected(value);
      },
    },
    dataHoursSelected: {
      get() {
        return this.getHoursSelected;
      },
      set(value) {
        this.setHoursSelected(value);
      },
    },
    dataMinutesSelected: {
      get() {
        return this.getMinutesSelected;
      },
      set(value) {
        this.setMinutesSelected(value);
      },
    },
    dataParticipantsSelected: {
      get() {
        return this.getParticipantsSelected;
      },
      set(value) {
        this.setParticipantsSelected(value);
      },
    },
    dataPrioritySelected: {
      get() {
        return this.getPrioritySelected;
      },
      set(value) {
        this.setPrioritySelected(value);
      },
    },
    dataActionMethodIdSelected: {
      get() {
        return this.getActionMethodIdSelected;
      },
      set(value) {
        this.setActionMethodIdSelected(value);
      },
    },
    dataActionSelected: {
      get() {
        return this.getActionSelected;
      },
      set(value) {
        this.setActionSelected(value);
      },
    },
  },
  methods: {
    ...mapActions([
      'actionCreateClientActionHistory',
      'getClientActionHistoryListActivities',
      'getClientActionHistoryListActivitiesHistory',
    ]),
    ...mapMutations([
      // Const Enum
      'setActionPlannedAtSelected',
      'setHoursSelected',
      'setMinutesSelected',
      'setParticipantsSelected',
      'setPrioritySelected',
      'setActionMethodIdSelected',
      'setActionSelected',
      'setDateFromSelected',

      // Alert
      'setAlertSuccess',
      'setAlertError',
    ]),

    save() {
      if (this.$refs.form.validate()) {
        const finishData = {
          clientId: parseInt(router.currentRoute.params.id),
          actionPlannedAt: this.getDateFromSelected
            ? new Date(
                `${this.getDateFromSelected}${
                  this.getHoursSelected && this.getMinutesSelected
                    ? ` ${this.getHoursSelected}:${this.getMinutesSelected}`
                    : ''
                }`,
              )
            : null,
          participants: this.getParticipantsSelected,
          priority: this.getPrioritySelected,
          actionMethodId: this.getActionMethodIdSelected,
          action: this.getActionSelected,
        };
        this.actionCreateClientActionHistory(finishData).then(async () => {
          this.setAlertSuccess('成功しました。');
          this.$emit('reloadTable');
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.setDateFromSelected(null);
      this.setActionPlannedAtSelected(null);
      this.setHoursSelected(null);
      this.setMinutesSelected(null);
      this.setParticipantsSelected(null);
      this.setPrioritySelected(null);
      this.setActionMethodIdSelected(null);
      this.setActionSelected(null);
      this.$refs.form.reset();
      this.valid = true;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}
::v-deep {
  .v-dialog {
    width: 404px;
    min-height: 580px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .v-select__selection--comma {
    font-size: 14px !important;
  }
  .v-icon {
    color: #333333;
  }
}
.activity-schedule-creating {
  color: #212121;
}
.label {
  font-size: 12px;
  color: #616161;
  margin: 0;
}
.multi-select {
  margin: 0;
  padding: 0;
  ::v-deep {
    .v-select__selection--comma {
      font-size: 12px;
      color: #424242;
    }
    .v-icon__svg {
      color: #333333;
    }
    .v-messages__message {
      font-size: 12px !important;
    }
  }
}
.colon {
  color: #616161;
  font-size: 20px;
  margin-top: 30px !important;
}
.activity-person {
  font-size: 12px;
  color: #212121;
}
.my-username {
  font-size: 16px;
  color: #616161;
}
.action {
  font-size: 10px;
  color: #000000;
}
.text-area {
  margin-top: 7px;
  min-height: 110px;
  width: 372px;
  border-color: #707070 !important;
  ::v-deep {
    .v-input__control {
      min-height: 110px !important;
    }
    .v-input__slot {
      min-height: 110px;
      margin-bottom: 0px;
    }
    .v-text-field__details {
      padding: 0 !important;
    }
    .v-text-field__slot {
      textarea {
        margin: 0 !important;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
}
.combobox-icon {
  ::v-deep {
    .v-icon {
      margin-bottom: 8px;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss">
.input-pos .v-select__slot {
  input[role='button'] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    font-size: 14px !important;
    color: #424242;
  }
  .v-input__append-inner {
    i {
      font-size: 24px !important;
    }
  }
}
</style>
