<template>
  <div
    style="position: relative; width: 100%;
    height: 100%;
    overflow: hidden;"
  >
    <div
      v-if="$route.query.action"
      class="dialog-cus"
      style="background-color: #999999;overflow: scroll;"
    >
      <div
        style="width: 25%;
      height: auto;
      background-color: white;
      border-radius: 9px;
      padding: 10px;
      font-size: 14px;margin-top: 10%;position: absolute;left: 37.5%;"
      >
        <v-form ref="form">
          <div class="mb-5">
            <h1>活動履歴登録</h1>
          </div>
          <div class="d-flex justify-space-between">
            <div class="div-left">
              <h4 class="title-field">折衡日</h4>
              <p class="mt-2">{{ $route.query.date }}</p>
            </div>
            <div class="ml-3 div-right">
              <h4 class="title-field">手段</h4>
              <v-select
                class="text-16px"
                dense
                :items="getEnumClientActionMethodList"
                item-text="name"
                item-value="id"
                v-model="dataActionMethodIdSelected"
              ></v-select>
            </div>
          </div>
          <!-- --- -->
          <v-row class="d-flex justify-space-between">
            <v-col cols="6" class="div-left">
              <h4 class="title-field">相手</h4>
              <v-select
                dense
                :items="getEnumParticipantTypeList"
                v-model="dataParticipantsSelected"
                multiple
                class="text-16px"
                :rules="rules.ruleSelectParticipants"
                required
              ></v-select>
            </v-col>
            <v-col
              cols="6"
              class="ml-3 div-right"
              style="margin: 0 !important"
              v-if="nameStaff"
            >
              <h4 class="activity-person">担当者</h4>
              <p class="text--title my-username" style="color: #000000">
                {{ nameStaff }}
              </p>
            </v-col>
            <v-col
              cols="6"
              class="ml-3 div-right"
              style="margin: 0 !important"
              v-else
            >
              <h4 class="activity-person">担当者</h4>
              <p class="text--title my-username">
                &lt; 自分のユーザー名 >
              </p>
            </v-col>
          </v-row>
          <!-- --- -->
          <div class="d-flex justify-space-between">
            <div class="div-right">
              <h4 class="title-field">対応内容</h4>
              <v-select
                dense
                :items="getEnumClientActionTypeList"
                item-text="name"
                item-value="id"
                v-model="dataClientActionTypeIdSelected"
              ></v-select>
            </div>
          </div>
          <!-- --- -->
          <div class="d-flex justify-space-between">
            <div class="div-left">
              <v-checkbox
                :value="getIsComplaintSelected"
                v-model="dataIsComplaintSelected"
                class="ml-2 text-14px"
                label="クレーム対応"
              ></v-checkbox>
            </div>
            <div class="ml-3 div-right">
              <v-select
                dense
                label="検討離脱理由"
                :items="getEnumNegotiationExitReasonList"
                v-model="dataReasonForWithdrawal"
                class="select-withdrawal"
              ></v-select>
            </div>
          </div>
          <!-- --- -->
          <div>
            <h5 class="title-field">アクション</h5>
            <v-textarea
              auto-grow
              v-model="dataRemarksSelected"
              :rules="[$rules.checkTextAreaLength()]"
              outlined
              rows="10"
              row-height="15"
              class="mt-1"
            ></v-textarea>
          </div>
          <!-- --- -->
          <div class="d-flex justify-end">
            <div>
              <v-btn
                style="width: 56px"
                class="t-btn--red-dark mr-4 btn-custom"
                @click="closeDialog"
              >
                閉じる
              </v-btn>
              <v-btn
                style="width: 68px"
                class="t-btn--prm btn-custom"
                @click="saveAdd"
              >
                保存
              </v-btn>
            </div>
          </div>
        </v-form>
      </div>
    </div>
    <div v-else>
      <customer-details>
        <template v-slot:btnPrope>
          <v-btn class="t-btn--prm mr-5 ml-2" :disabled="!checkPerUser">
            {{ $t('task_29786.keep') }}
          </v-btn>
        </template>
        <div class="mx-5 my-5">
          <div class="mt-3">
            <v-card>
              <div class="d-flex justify-space-between px-5">
                <h2 class="text--title my-4">
                  {{ $t('task_29786.ActivitiesTab.ActivitySchedule') }}
                </h2>
                <div>
                  <v-btn
                    class="t-btn--prm mt-4"
                    @click="(visible = 1), (activity = true)"
                    :disabled="!checkPerUser"
                  >
                    <span class="text-12px"
                      ><i class="fas fa-plus"></i>&nbsp;{{
                        $t(
                          'task_29786.ActivitiesTab.ActivityScheduleRegistration',
                        )
                      }}</span
                    >
                  </v-btn>
                </div>
              </div>
              <Table
                :attr="{
                  dense: true,
                  'no-data-text': $t('rules.noData'),
                  'item-key': 'id',
                  class: 'custom-activity-schedule-table',
                }"
                ref="table"
                :items="clientActionHistoryListActivities"
                :itemsPerPage="30"
                :itemsPerPageOptions="[30, 60, 120, 480, 1000]"
                :headers="headerActivitySchedule"
                :hideFooter="true"
                :disablePagination="true"
              >
                <template v-slot:[`item.actionPlannedAt`]="{ item }">
                  <div v-if="item.actionPlannedAt">
                    {{
                      item.actionPlannedAt | formatDateTimeISONoSecond
                    }}
                  </div>
                </template>
                <template v-slot:[`item.staffName`]="{ item }">
                  <div v-if="item.staff">
                    {{ item.staff.name ? item.staff.name : '' }}
                  </div>
                </template>
                <template v-slot:[`item.participantsName`]="{ item }">
                  <div v-if="item.participants">
                    {{ item.participants.join(', ') }}
                  </div>
                </template>
                <template v-slot:[`item.actionMethodName`]="{ item }">
                  <div v-if="item.actionMethod">
                    <span>{{ item.actionMethod.name }}</span>
                  </div>
                </template>
                <template v-slot:[`item.priority`]="{ item }">
                  <div>
                    <span v-if="item.priority === 0">低</span>
                    <span v-if="item.priority === 5">中</span>
                    <span v-if="item.priority === 10">高</span>
                  </div>
                </template>
                <template v-slot:[`item.ActivityHistory`]="{ item }">
                  <v-chip
                    color="#32B679"
                    label
                    text-color="white"
                    @click="showPopupActivityHistory(item, item.id, 'Register')"
                    :disabled="!checkPerUser"
                  >
                    {{ $t('task_29786.ActivitiesTab.MoveToActivityHistory') }}
                  </v-chip>
                </template>

                <template v-slot:[`item.edit`]="{ item }">
                  <v-btn
                    v-on:click="showPopupActivityScheduleEdit(item)"
                    icon
                    :disabled="!checkPerUser"
                  >
                    <v-icon x-large :class="checkPerUser ? 'btn-crm-primary' : ''"
                      >mdi-pencil-circle</v-icon
                    >
                  </v-btn>
                </template>
              </Table>
            </v-card>
          </div>
          <div class="mt-5">
            <v-card>
              <div class="d-flex justify-space-between px-5">
                <h2 class="text--title my-4">
                  {{ $t('task_29786.ActivitiesTab.ActivityHistory') }}
                </h2>
                <div>
                  <v-btn
                    class="t-btn--prm mt-4"
                    @click="showPopupActivityHistoryCreate"
                    :disabled="!checkPerUser"
                  >
                    <span class="text-12px"
                      ><i class="fas fa-plus"></i>&nbsp;{{
                        $t(
                          'task_29786.ActivitiesTab.ActivityHistoryRegistration',
                        )
                      }}</span
                    >
                  </v-btn>
                </div>
              </div>
              <Table
                :attr="{
                  dense: true,
                  'no-data-text': $t('rules.noData'),
                  'item-key': 'id',
                  class: 'custom-activity-schedule-table',
                }"
                ref="table"
                :items="clientActionHistoryListActivitiesHistory"
                :headers="headerActivityHistory"
                :multiSort="true"
                :hideFooter="true"
                :disablePagination="true"
              >
                <template v-slot:[`item.staffName`]="{ item }">
                  <div v-if="item.staff">
                    {{ item.staff.name ? item.staff.name : '' }}
                  </div>
                </template>
                <template v-slot:[`item.remarks`]="{ item }">
                  {{ item.remarks }}
                </template>
                <template v-slot:[`item.participants`]="{ item }">
                  <div v-if="item.participants">
                    {{ item.participants.join(', ') }}
                  </div>
                </template>
                <template v-slot:[`item.actionMethodName`]="{ item }">
                  <div v-if="item.actionMethod">
                    <span>{{ item.actionMethod.name }}</span>
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div style="width:300px">
                    {{ item.action }}
                  </div>
                </template>
                <template v-slot:[`item.isComplaint`]="{ item }">
                  <div v-if="item.isComplaint">
                    クレーム対応
                  </div>
                  <div v-else></div>
                </template>
                <template v-slot:[`item.CustomerRatingChange`]="{ item }">
                  <div
                    class="mt-1"
                    v-if="
                      item.purchaseRateBefore !== null ||
                        item.purchaseRate !== null
                    "
                  >
                    <span>購入評価&nbsp;</span>
                    <span>{{
                      item.purchaseRateBefore !== null
                        ? item.purchaseRateBefore.name
                        : ''
                    }}</span>
                    <span>{{
                      item.purchaseRateBefore !== null &&
                      item.purchaseRate !== null
                        ? '→'
                        : ''
                    }}</span>
                    <span>{{
                      item.purchaseRate !== null ? item.purchaseRate.name : ''
                    }}</span>
                  </div>
                  <div
                    class="mt-1"
                    v-if="
                      item.furtherPurchaseRateBefore !== null ||
                        item.furtherPurchaseRate !== null
                    "
                  >
                    <span>買増評価&nbsp;</span>
                    <span>{{
                      item.furtherPurchaseRateBefore !== null
                        ? item.furtherPurchaseRateBefore.name
                        : ''
                    }}</span>
                    <span>{{
                      item.furtherPurchaseRateBefore !== null &&
                      item.furtherPurchaseRate !== null
                        ? '→'
                        : ''
                    }}</span>
                    <span>{{
                      item.furtherPurchaseRate !== null
                        ? item.furtherPurchaseRate.name
                        : ''
                    }}</span>
                  </div>
                  <div
                    class="mt-1"
                    v-if="
                      item.renewPurchaseRateBefore !== null ||
                        item.renewPurchaseRate !== null
                    "
                  >
                    <span>更新評価&nbsp;</span>
                    <span>{{
                      item.renewPurchaseRateBefore !== null
                        ? item.renewPurchaseRateBefore.name
                        : ''
                    }}</span>
                    <span>{{
                      item.renewPurchaseRateBefore !== null &&
                      item.renewPurchaseRate !== null
                        ? '→'
                        : ''
                    }}</span>
                    <span>{{
                      item.renewPurchaseRate !== null
                        ? item.renewPurchaseRate.name
                        : ''
                    }}</span>
                  </div>
                </template>
                <template v-slot:[`item.CTIPhoneHistory`]="{ item }">
                  <v-btn
                    icon
                    v-if="item.kujiraCrmUrl"
                    @click="showPopupActivityHistoryRegistration(item)"
                  >
                    <i class="fas fa-info-circle"></i>
                  </v-btn>
                </template>
                <template v-slot:[`item.edit`]="{ item }">
                  <v-btn
                    @click="showPopupActivityHistory(item, item.id, 'Edit')"
                    icon
                    :disabled="!checkPerUser"
                  >
                    <v-icon x-large :class="checkPerUser ? 'btn-crm-primary' : ''"
                      >mdi-pencil-circle</v-icon
                    >
                  </v-btn>
                </template>
              </Table>
            </v-card>
          </div>
        </div>
        <!-- Dialog -->
        <activity-schedule-registration
          :visible="visible === 1"
          :isActive="activity"
          @close="visible = -1"
          @reloadTable="reloadTable"
        ></activity-schedule-registration>
        <activity-schedule-editing
          :visible="visible === 2"
          @close="visible = -1"
          :currentTable1RowId="currentTable1RowId"
          :userName="userName"
          @reloadTable="reloadTable"
        ></activity-schedule-editing>
        <activity-history
          :visible="visible === 3"
          @close="visible = -1"
          :currentTable1RowId="currentTable1RowId"
          :buttonType="buttonType"
          :userName="userName"
          @reloadTable="reloadTable"
        ></activity-history>
        <activity-history-create
          :visible="visible === 4"
          @close="visible = -1"
          :propsData="propsData"
          @reloadTable="reloadTable"
        ></activity-history-create>
        <activity-history-registration
          :visible="visible === 5"
          :date="dateCTI ? dateCTI : dateToMyPage"
          :actionStaffID="actionStaffID"
          :nameStaff="userName"
          @close="visible = -1"
        ></activity-history-registration>
      </customer-details>
    </div>
  </div>
</template>

<script>
import ActivityScheduleEditing from './ActivityScheduleEditing.vue';
import ActivityHistory from './ActivityHistory.vue';
import ActivityScheduleRegistration from './ActivityScheduleRegistration.vue';
import ActivityHistoryCreate from './ActivityHistoryCreate.vue';
import CustomerDetails from '../CustomerDetails.vue';
import ActivityHistoryRegistration from './ActivityHistoryRegistration.vue';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import router from '@/router';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import Table from '@/components/Table/index.vue';

export default {
  components: {
    ActivityScheduleRegistration,
    ActivityScheduleEditing,
    ActivityHistory,
    ActivityHistoryCreate,
    CustomerDetails,
    ActivityHistoryRegistration,
    Table,
  },
  name: 'Activities',
  data() {
    return {
      propsData: {
        purchaseRateId: null,
        furtherPurchaseRateId: null,
        renewPurchaseRateId: null,
      },
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      dateCTI: '',
      visible: -1,
      actionStaffID: null,
      activity: true,
      nameStaff: localStorage.getItem('name'),
      headerActivitySchedule: [
        {
          text: '活動予定日',
          value: 'actionPlannedAt',
          width: '150px',
          sortable: true,
        },
        {
          text: '活動担当者',
          value: 'staffName',
          width: '150px',
          sortable: false,
        },
        {
          text: '相手',
          value: 'participantsName',
          width: '150px',
          sortable: false,
        },
        {
          text: '手段',
          value: 'actionMethodName',
          width: '150px',
          sortable: false,
        },
        {
          text: 'アクション',
          value: 'action',
          width: '250px',
          sortable: false,
        },
        {
          text: this.$t('task_29786.ActivitiesTab.priority'),
          value: 'priority',
          width: '150px',
          sortable: false,
        },
        {
          text: '',
          value: 'ActivityHistory',
          align: 'right',
          sortable: false,
        },
        {
          text: this.$t('task_29786.ActivitiesTab.edit'),
          value: 'edit',
          width: '70px',
          sortable: false,
        },
      ],
      headerActivityHistory: [
        {
          text: 'ID',
          value: 'id',
          width: '50px',
          sortable: true,
        },
        {
          text: '折衝日',
          value: 'actionAt',
          width: '150px',
          sortable: true,
        },
        {
          text: '活動担当者',
          value: 'staffName',
          width: '150px',
          sortable: false,
        },
        {
          text: '相手',
          value: 'participants',
          width: '100px',
          sortable: false,
        },
        {
          text: '手段',
          value: 'actionMethodName',
          width: '100px',
          sortable: false,
        },
        {
          text: '状況',
          value: 'remarks',
          sortable: false,
        },
        {
          text: 'クレーム対応',
          value: 'isComplaint',
          sortable: false,
        },
        {
          text: '顧客評価変更',
          value: 'CustomerRatingChange',
          sortable: false,
        },
        {
          text: this.$t('task_29786.ActivitiesTab.CTIPhoneHistory'),
          value: 'CTIPhoneHistory',
          sortable: false,
        },
        {
          text: this.$t('task_29786.ActivitiesTab.edit'),
          value: 'edit',
          sortable: false,
        },
      ],
      currentTable1RowId: null,
      userName: null,
      // add new
      rules: {
        ruleSelectParticipants: [
          v => !!v || 'アイテムが必要です',
          v => !(v && v.length === 0) || 'アイテムが必要です',
        ],
      },
      buttonType: null,
    };
  },
  computed: {
    ...mapGetters([
      'clientActionHistoryListActivities',
      'clientActionHistoryListActivitiesHistory',
      'dateToMyPage',
      'getChangeCreateActivityHistoryRegistration',
    ]),
    email() {
      return !this.$store.getters.user ? false : this.$store.getters.user.email;
    },
    ...mapGetters([
      // Const Enum
      'getEnumNegotiationExitReasonList',
      'getEnumParticipantTypeList',
      'getEnumClientActionMethodList',
      // Data call API
      'getClientActionHistory',
      'getCurrentPurchaseRating',
      'getEnumPurchaseRateList',
      'getEnumClientActionTypeList',
      'getCurrentPurchaseRate',
      // Select default
      'getParticipants',
      'getActionMethodId',
      'getAction',
      // Selected
      'getParticipantsSelected',
      'getActionMethodIdSelected',
      'getIsComplaintSelected',
      'getClientActionTypeIdSelected',
      'getReasonForWithdrawalSelected',
      'getRemarksSelected',
    ]),
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    dataActionMethodIdSelected: {
      get() {
        return this.getActionMethodIdSelected;
      },
      set(value) {
        this.setActionMethodIdSelected(value);
      },
    },
    dataParticipantsSelected: {
      get() {
        return this.getParticipantsSelected;
      },
      set(value) {
        this.setParticipantsSelected(value);
      },
    },
    dataClientActionTypeIdSelected: {
      get() {
        return this.getClientActionTypeIdSelected;
      },
      set(value) {
        this.setClientActionTypeIdSelected(value);
      },
    },
    dataIsComplaintSelected: {
      get() {
        return this.getIsComplaintSelected;
      },
      set(value) {
        this.setIsComplaintSelected(value);
      },
    },
    dataRemarksSelected: {
      get() {
        return this.getRemarksSelected;
      },
      set(value) {
        this.setRemarksSelected(value);
      },
    },
    dataReasonForWithdrawal: {
      get() {
        return this.getReasonForWithdrawalSelected;
      },
      set(value) {
        this.setReasonForWithdrawalSelected(value);
      },
    },
  },
  mounted() {
    this.getClientActionHistoryListActivities();
    this.getClientActionHistoryListActivitiesHistory();
    // Enum
    this.actionEnumNegotiationExitReasonList();
    this.actionEnumPurchaseRateList();
    this.actionEnumParticipantTypeList();
    this.actionEnumClientActionMethodList();
    // this.actionStaff(parseInt(router.currentRoute.params.id));
    this.actionStaffID = parseInt(router.currentRoute.params.id);
    this.actionEnumClientActionTypeList();
  },
  watch: {
    getChangeCreateActivityHistoryRegistration: function check() {
      this.getClientActionHistoryListActivities();
      this.getClientActionHistoryListActivitiesHistory();
    },
  },
  methods: {
    ...mapActions([
      'getClientActionHistoryListActivities',
      'getClientActionHistoryListActivitiesHistory',
      'proClientBasicInfo',
      // get Enum
      'actionEnumNegotiationExitReasonList',
      'actionEnumPurchaseRateList',
      'actionGetClientActionHistory',
      'actionEnumParticipantTypeList',
      'actionEnumClientActionMethodList',
      // 'actionStaff',
      'actionEnumClientActionTypeList',
      'actionCreateActivityHistoryRegistration',
    ]),
    ...mapMutations([
      'setActionSelected',
      'setActionAtSelected',
      'setHoursSelected',
      'setMinutesSelected',
      'setParticipantsSelected',
      'setPrioritySelected',
      'setActionMethodIdSelected',
      'setPurchaseRateIdSelected',
      'setCurrentPurchaseRate',
      'setIsComplaintSelected',
      'setFurtherPurchaseRateIdSelected',
      'setRenewPurchaseRateIdSelected',
      'setCurrentFurtherPurchaseRate',
      'setCurrentRenewPurchaseRate',
      'setNegotiationExitReasonSelected',
      'setClientActionTypeIdSelected',
      'setRemarksSelected',
      'setDateFromSelected',
      'setChangeCreateActivityHistoryRegistration',
      'setReasonForWithdrawalSelected',
      'setAlertSuccess',
    ]),
    reloadTable() {
      this.getClientActionHistoryListActivities();
      this.getClientActionHistoryListActivitiesHistory();
    },
    showPopupActivityScheduleEdit(item) {
      this.actionGetClientActionHistory(item.id).then(clientActionHistory => {
        if (clientActionHistory.actionPlannedAt) {
          this.setDateFromSelected(
            moment(clientActionHistory.actionPlannedAt).format('YYYY-MM-DD'),
          );
          this.setHoursSelected(
            moment(clientActionHistory.actionPlannedAt).format('HH'),
          );
          this.setMinutesSelected(
            moment(clientActionHistory.actionPlannedAt).format('mm'),
          );
        } else {
          this.setDateFromSelected(null);
          this.setHoursSelected(null);
          this.setMinutesSelected(null);
        }
        if (clientActionHistory.participants) {
          this.setParticipantsSelected(clientActionHistory.participants);
        } else {
          this.setParticipantsSelected(null);
        }
        if (clientActionHistory.priority || clientActionHistory.priority === 0) {
          this.setPrioritySelected(clientActionHistory.priority);
        } else {
          this.setPrioritySelected(null);
        }
        if (clientActionHistory.actionMethodId) {
          this.setActionMethodIdSelected(clientActionHistory.actionMethodId);
        } else {
          this.setActionMethodIdSelected(null);
        }
        if (clientActionHistory.action) {
          this.setActionSelected(clientActionHistory.action);
        } else {
          this.setActionSelected(null);
        }
        this.currentTable1RowId = item.id;
        this.userName = item.staff?.name;
        this.visible = 2;
      });
    },
    showPopupActivityHistory(item, id, buttonType) {
      this.actionGetClientActionHistory(id).then(clientActionHistory => {
        if (buttonType === 'Register') {
          // actionAt
          if (item.actionPlannedAt) {
            this.setActionAtSelected(
              new Date(item.actionPlannedAt).toISOString().slice(0, 10),
            );
          } else {
            this.setActionAtSelected(null);
          }
          this.proClientBasicInfo(parseInt(router.currentRoute.params.id)).then(
            clientBasicInfo => {
              // FurtherPurchaseRateIdSelected
              this.setFurtherPurchaseRateIdSelected(
                clientBasicInfo.furtherPurchaseRateId,
              );
              // RenewPurchaseRateIdSelected
              this.setRenewPurchaseRateIdSelected(
                clientBasicInfo.renewPurchaseRateId,
              );
              // PurchaseRateId
              this.setPurchaseRateIdSelected(clientBasicInfo.purchaseRateId);
            },
          );
        } else {
          // actionAt
          if (clientActionHistory.actionAt) {
            this.setActionAtSelected(clientActionHistory.actionAt);
          } else {
            this.setActionAtSelected(null);
          }
          // FurtherPurchaseRateIdSelected
          this.setFurtherPurchaseRateIdSelected(
            clientActionHistory.furtherPurchaseRateId,
          );
          // RenewPurchaseRateIdSelected
          this.setRenewPurchaseRateIdSelected(
            clientActionHistory.renewPurchaseRateId,
          );

          // PurchaseRateId
          if (clientActionHistory.purchaseRateId) {
            this.setPurchaseRateIdSelected(clientActionHistory.purchaseRateId);

            this.actionEnumPurchaseRateList().then(enumPurchaseRateList => {
              const currentPurchaseRate = enumPurchaseRateList.find(
                element => element.id === clientActionHistory.purchaseRateId,
              );

              const currentFurtherPurchaseRate = enumPurchaseRateList.find(
                element =>
                  element.id === clientActionHistory.furtherPurchaseRateId,
              );

              const currentRenewPurchaseRate = enumPurchaseRateList.find(
                element =>
                  element.id === clientActionHistory.renewPurchaseRateId,
              );

              if (currentPurchaseRate) {
                this.setCurrentPurchaseRate(currentPurchaseRate);
              } else {
                this.setCurrentPurchaseRate(null);
              }

              if (currentFurtherPurchaseRate) {
                this.setCurrentFurtherPurchaseRate(currentFurtherPurchaseRate);
              } else {
                this.setCurrentFurtherPurchaseRate(null);
              }

              if (currentRenewPurchaseRate) {
                this.setCurrentRenewPurchaseRate(currentRenewPurchaseRate);
              } else {
                this.setCurrentRenewPurchaseRate(null);
              }
            });
          } else {
            this.setPurchaseRateIdSelected(null);
            this.setCurrentPurchaseRate(null);
            this.setCurrentFurtherPurchaseRate(null);
            this.setCurrentRenewPurchaseRate(null);
          }
        }

        if (clientActionHistory.remarks) {
          this.setRemarksSelected(clientActionHistory.remarks);
        } else {
          this.setRemarksSelected(null);
        }

        // Common buttonType = 'Edit' or 'Register'
        // participants
        if (clientActionHistory.participants) {
          this.setParticipantsSelected(clientActionHistory.participants);
        } else {
          this.setParticipantsSelected(null);
        }

        // priority
        if (clientActionHistory.priority) {
          this.setPrioritySelected(clientActionHistory.priority);
        } else {
          this.setPrioritySelected(null);
        }

        // actionMethodId
        if (clientActionHistory.actionMethodId) {
          this.setActionMethodIdSelected(clientActionHistory.actionMethodId);
        } else {
          this.setActionMethodIdSelected(null);
        }

        // action
        if (clientActionHistory.action) {
          this.setActionSelected(clientActionHistory.action);
        } else {
          this.setActionSelected(null);
        }

        // isComplaint
        this.setIsComplaintSelected(clientActionHistory.isComplaint);

        // setNegotiationExitReasonSelected
        if (clientActionHistory.negotiationExitReason) {
          this.setNegotiationExitReasonSelected(
            clientActionHistory.negotiationExitReason.split(', '),
          );
        } else {
          this.setNegotiationExitReasonSelected([]);
          this.setCurrentPurchaseRate(null);
          this.setCurrentFurtherPurchaseRate(null);
          this.setCurrentRenewPurchaseRate(null);
        }

        // setClientActionTypeIdSelected
        this.setClientActionTypeIdSelected(
          clientActionHistory.clientActionTypeId,
        );
      });

      this.currentTable1RowId = id;
      // buttonType = 'Edit' or 'Register'
      this.buttonType = buttonType;
      this.userName = item.staff?.name;
      this.visible = 3;
    },
    showPopupActivityHistoryCreate() {
      this.proClientBasicInfo(parseInt(router.currentRoute.params.id)).then(
        async clientBasicInfo => {
          this.propsData = await {
            purchaseRateId: clientBasicInfo.purchaseRateId,
            furtherPurchaseRateId: clientBasicInfo.furtherPurchaseRateId,
            renewPurchaseRateId: clientBasicInfo.renewPurchaseRateId,
          };
          this.visible = 4;
        },
      );
    },

    showPopupActivityHistoryRegistration(item) {
      window.open(item.kujiraCrmUrl);
    },
    saveAdd() {
      if (this.$refs.form.validate()) {
        if (this.getParticipantsSelected) {
          if (this.getParticipantsSelected.length !== 0) {
            const finishData = {
              clientId: this.actionStaffID,
              actionAt: this.$route.query.date,
              actionMethodId: this.getActionMethodIdSelected,
              participants: this.getParticipantsSelected,
              isComplaint: this.getIsComplaintSelected
                ? this.getIsComplaintSelected
                : false,
              exitReason: this.getReasonForWithdrawalSelected
                ? this.getReasonForWithdrawalSelected
                : '',
              actionTypeId: this.getClientActionTypeIdSelected,
              remarks: this.getRemarksSelected,
              kujiraCrmId: this.$route.query.crmid,
              kujiraCrmTel: this.$route.query.tel,
              kujiraCrmDate: this.$route.query.date,
            };
            this.actionCreateActivityHistoryRegistration(finishData).then(
              () => {
                this.setAlertSuccess('成功した活動履歴の登録');
                this.closeDialog();
              },
            );
          }
        }
      }
    },
    closeDialog() {
      this.setParticipantsSelected(null);
      this.setActionMethodIdSelected(null);
      this.setClientActionTypeIdSelected(null);
      this.setReasonForWithdrawalSelected(null);
      this.setRemarksSelected(null);
      this.setIsComplaintSelected(null);
      this.$refs.form.resetValidation();
      window.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.component {
  &-header,
  &-main {
    font-size: var(--unnamed-font-size-16);
    color: var(--text_active_000000);
    font-weight: 500;
  }
}
.blod {
  font-weight: bold;
}
.intro-info {
  div {
    display: flex;
    label {
      width: 121px;
      flex-shrink: 0;
      text-align: right;
      margin-right: 16px;
    }
  }
}
.tab-custom {
  &__tab {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__total {
    width: 40%;
    text-align: right;
    align-self: flex-end;
    color: var(--text_active_000000);
  }
}
.info-detail-title {
  color: var(--text_active_000000);
}
@media (max-width: 600px) {
  .v-card__title {
    flex-direction: column;
    align-items: flex-end;
  }
  .intro-info div {
    display: block;
    label {
      width: auto;
      text-align: left;
    }
  }
}
.userIcon {
  height: 100px;
  width: 100px;
  margin: auto;
}
.listCmt {
  width: 1000px;
  border-bottom: 1px solid #d8d8d8;
  margin: auto;
  padding: 10px 0;
}
.borderComment {
  border: 1px solid #d8d8d8;
}
.colorUsername {
  color: green;
}
.headerCmt {
  border-bottom: 1px solid #d8d8d8;
  padding: 15px 15px 0px 15px;
}
.footerCmt {
  border-top: 1px solid #d8d8d8;
  padding: 15px;
}
.borderPaginate {
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
}
.selectCustom {
  width: 10%;
  margin-right: 10px;
}
.fa-info-circle {
  font-size: 35px;
  color: #13ace0;
}
.color-title {
  color: #0c6786;
}

::v-deep {
  .dialog-cus {
    border-radius: inherit;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: inherit;
    width: 100%;
    will-change: opacity;
  }
  .custom-activity-schedule-table {
    thead th,
    // .v-data-footer {
    //   justify-content: center !important;
    // }
    tbody > tr {
      height: 48px !important;
    }
    th span,
    tr {
      th {
        white-space: pre;
        color: #000000 !important;
      }
      td {
        color: #424242;
        max-width: 18vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .custom-activity-history-table {
    thead th,
      // .v-data-footer {
      //   justify-content: center !important;
      // }
      tbody > tr {
      height: 48px !important;
    }
    th span,
    tr {
      th {
        white-space: pre;
        color: #000000 !important;
      }
      td {
        color: #424242;
        max-width: 18vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
