<template>
  <main-layout>
    <v-dialog v-model="dialog">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="mb-5">
          <h1>活動履歴登録</h1>
        </div>
        <div class="d-flex justify-space-between">
          <div class="div-left">
            <h4 class="title-field">折衡日</h4>
            <div>
              <v-menu
                v-model="menuDateFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                class="pb-0 mb-0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div class="d-flex align-center">
                    <v-combobox
                      :value="finishData.actionAt"
                      readonly
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      class="text-size-normal pt-0 combobox-icon input-pos"
                      :append-icon="attrs['aria-expanded'] === 'true' ? 'arrow_drop_up' : 'arrow_drop_down'"
                    ></v-combobox>
                  </div>
                </template>
                
                <v-date-picker
                  v-model="finishData.actionAt"
                  :first-day-of-week="0"
                  :locale="$i18n.locale"
                  scrollable
                  @input="
                    menuDateFrom = false;
                    menuDateTo = true;
                  "
                  class="v-date-picker-custom"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="ml-3 div-right">
            <h4 class="title-field">手段</h4>
            <v-select
              class="text-16px"
              dense
              :items="getEnumClientActionMethodList"
              item-text="name"
              item-value="id"
              v-model="finishData.actionMethodId"
            ></v-select>
          </div>
        </div>
        <!-- --- -->
        <div class="d-flex justify-space-between">
          <div class="div-left">
            <h4 class="title-field">相手</h4>
            <v-select
              dense
              :items="getEnumParticipantTypeList"
              v-model="finishData.participants"
              multiple
              class="text-16px"
              :rules="rules.ruleSelectParticipants"
              required
            ></v-select>
          </div>
          <div class="ml-3 div-right">
            <h4 class="title-field">担当者</h4>
            <p class="mt-2 text-field" v-if="currentUserLoginName">
              {{ currentUserLoginName }}
            </p>
            <p class="mt-2 text-field" v-else>&lt; {{ manager }} ></p>
          </div>
        </div>
        <!-- --- -->
        <div class="d-flex justify-space-between">
          <div class="div-left">
            <h5>現在の購入評価</h5>
            <p class="mt-2">
              {{ getName('PurchaseRate') }}
            </p>
          </div>
          <div class="ml-3 div-right">
            <h5>購入評価</h5>
            <v-select
              dense
              :items="getEnumPurchaseRateList"
              item-text="name"
              item-value="id"
              v-model="finishData.purchaseRateId"
              class="text-14px"
            ></v-select>
          </div>
        </div>
        <!-- Further Purchase Rate -->
        <div class="d-flex justify-space-between">
          <div class="div-left">
            <h4 class="text-10px">現在の買増評価</h4>
            <p class="mt-2">
              {{ getName('FurtherPurchaseRate') }}
            </p>
          </div>
          <div class="ml-3 div-right">
            <h4 class="text-10px">買増評価</h4>
            <v-select
              dense
              :items="getEnumPurchaseRateList"
              item-text="name"
              item-value="id"
              v-model="finishData.furtherPurchaseRateId"
              class="text-14px"
              :value="propsData.furtherPurchaseRateId"
            ></v-select>
          </div>
        </div>
        <!-- Renew Purchase Rate -->
        <div class="d-flex justify-space-between">
          <div class="div-left">
            <h4 class="text-10px">現在の更新評価</h4>
            <p class="mt-2">
              {{ getName('RenewPurchaseRate') }}
            </p>
          </div>
          <div class="ml-3 div-right">
            <h4 class="text-10px">更新評価</h4>
            <v-select
              dense
              :items="getEnumPurchaseRateList"
              item-text="name"
              item-value="id"
              v-model="finishData.renewPurchaseRateId"
              class="text-14px"
              :value="propsData.renewPurchaseRateId"
            ></v-select>
          </div>
        </div>
        <!-- --- -->
        <div class="d-flex justify-space-between">
          <div class="div-left">
            <v-checkbox
              v-model="finishData.isComplaint"
              class="ml-2 text-14px"
              label="クレーム対応"
            ></v-checkbox>
          </div>
          <div class="ml-3 div-right">
            <v-combobox
              :items="getEnumNegotiationExitReasonList"
              v-model="finishData.negotiationExitReason"
              class="select-withdrawal"
              label="検討離脱理由"
              multiple
            ></v-combobox>
          </div>
        </div>
        <!-- --- -->
        <div class="d-flex justify-space-between">
          <div class="div-right">
            <h4 class="title-field">対応内容</h4>
            <v-select
              dense
              :items="getEnumClientActionTypeList"
              item-text="name"
              item-value="id"
              v-model="finishData.clientActionTypeId"
            ></v-select>
          </div>
        </div>
        <!-- --- -->
        <div>
          <h5 class="title-textarea">状況</h5>
          <v-textarea
            v-model="finishData.remarks"
            outlined
            rows="10"
            row-height="15"
            class="mt-1"
            :rules="[$rules.checkTextAreaLength()]"
          ></v-textarea>
        </div>
        <!-- --- -->
        <div class="margin-top:30px">
          <div class="float-right">
            <v-btn
              style="width: 56px"
              class="t-btn--red-dark mr-4 btn-custom"
              @click="closeDialog"
            >
              閉じる
            </v-btn>
            <v-btn
              style="width: 68px"
              class="t-btn--prm btn-custom"
              @click="handleCreate"
            >
              保存
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </main-layout>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import MainLayout from '@/layouts/MainLayout';

export default {
  components: { MainLayout },
  name: 'ActivityHistoryCreate',
  data() {
    return {
      valid: true,
      manager: '自分のユーザー名',

      // v-date-picker-custom
      menuDateFrom: false,
      menuDateTo: false,
      dateTo: new Date().toISOString().substr(0, 10),
      rules: {
        ruleSelectParticipants: [
          v => !!v || '必須項目です。',
          v => !(v && v.length === 0) || '必須項目です。',
        ],
      },
      finishData: {
        clientId: parseInt(this.$route.params.id),
        participants: [],
        actionMethodId: null,
        remarks: null,
        actionAt: null,
        purchaseRateId: null,
        furtherPurchaseRateId: null,
        renewPurchaseRateId: null,
        isComplaint: false,
        negotiationExitReason: null,
        clientActionTypeId: null,
      },
      currentUserLoginName: localStorage.getItem('name')
    };
  },
  props: {
    visible: Boolean,
    propsData: Object
  },
  watch: {
    propsData () {
      this.finishData.purchaseRateId = this.propsData.purchaseRateId;
      this.finishData.furtherPurchaseRateId = this.propsData.furtherPurchaseRateId;
      this.finishData.renewPurchaseRateId = this.propsData.renewPurchaseRateId;
    }
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },

    ...mapGetters([
      // Const Enum
      'getEnumNegotiationExitReasonList',
      'getEnumParticipantTypeList',
      'getEnumClientActionMethodList',
      'getEnumPurchaseRateList',
      'getEnumClientActionTypeList',
      'getClientBasicInfoPro',
      // Data call API
      'getCurrentPurchaseRate',
      'getCurrentFurtherPurchaseRate',
      'getCurrentRenewPurchaseRate',
    ]),
  },
  methods: {
    ...mapActions(['actionActivityHistoryCreate']),
    ...mapMutations([
      'setAlertSuccess',
      'setAlertError',
    ]),

    handleCreate () {
      if (this.$refs.form.validate()) {
        let isComplaint = false
        if (this.finishData.isComplaint) {
          isComplaint = this.finishData.isComplaint
        }
        const finishData = {
          data: {
            clientId: parseInt(this.$route.params.id),
            participants: this.finishData.participants,
            actionMethodId: this.finishData.actionMethodId,
            remarks: this.finishData.remarks,
            actionAt: this.finishData.actionAt,
            purchaseRateId: this.finishData.purchaseRateId,
            furtherPurchaseRateId: this.finishData.furtherPurchaseRateId,
            renewPurchaseRateId: this.finishData.renewPurchaseRateId,
            isComplaint: isComplaint,
            negotiationExitReason: this.finishData.negotiationExitReason ? this.finishData.negotiationExitReason.join(', ') : null,
            clientActionTypeId: this.finishData.clientActionTypeId,
          },
        };
        this.actionActivityHistoryCreate(finishData).then(result => {
          this.setAlertSuccess('成功しました。');
          this.$emit('reloadTable')
          this.closeDialog();
        })
      }
    },

    closeDialog() {
      this.valid = true;
      this.$refs.form.reset();
      this.finishData.actionAt = null; // for some reason actionAt doesn't get reset, so do that manually
      this.$emit('close');
    },

    getName (type) {
      if (this.getEnumPurchaseRateList && this.getEnumPurchaseRateList.length > 0 && this.getClientBasicInfoPro) {
        if (type === 'PurchaseRate') {
          const itemObj = this.getEnumPurchaseRateList.find(element => 
            this.getClientBasicInfoPro.purchaseRateId === element.id
          );
          if (itemObj) {
            return itemObj.name
          } else {
            return null
          }
        }
        if (type === 'FurtherPurchaseRate') {
          const itemObj = this.getEnumPurchaseRateList.find(element => 
            this.getClientBasicInfoPro.furtherPurchaseRateId === element.id
          );
          if (itemObj) {
            return itemObj.name
          } else {
            return null
          }
        }
        if (type === 'RenewPurchaseRate') {
          const itemObj = this.getEnumPurchaseRateList.find(element => 
           this.getClientBasicInfoPro.renewPurchaseRateId === element.id
          );
          if (itemObj) {
            return itemObj.name
          } else {
            return null
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content--fixed{
  // top:280px !important;
  #list-266{
    display: none;
  }
}
h1{
  font-size: 28px !important;
  color: #212121 !important;
  font-weight: bold !important;
}
h5{
  font-size: 10px !important;
  color: #000 !important;
  font-weight: 600 !important;
}
::v-deep {
  .v-dialog {
    width: 426px;
    // height: 661px;
    background-color: white;
    border-radius: 9px;
    padding: 16px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  textarea{
  height: 150px;
  }
  .v-textarea {
  
    textarea {
      font-size: 14x !important;
      color: #000000 !important;
    }
  }
  .text-10px {
    color: #000000 !important;
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  // label
  .title-field {
    color: #212121;
    font-size: 12px;
    font-weight: 600;
  }
  // div
  .div-left {
    width: 175px;
  }
  .div-right {
    width: 203px;
  }
  // select and input
  .v-select__selection {
    font-size: 12px !important;
    color: #000000;
  }
  .v-input__icon {
    svg {
      color: #333333;
    }
  }
  .v-input--checkbox {
    .v-label {
      font-size: 12px !important;
      color: #000000;
    }
  }
  // btn
  .btn-custom {
    height: 32px !important;
    font-size: 12px !important;
  }
  .text-field {
    color: #616161;
    font-size: 16px;
  }
  .text-16px {
    .v-select__selection {
      color: #000000;
      font-size: 16px !important;
    }
  }
  .text-14px {
    .v-select__selection {
      color: #000000;
      font-size: 14px !important;
    }
  }
  .select-withdrawal {
    .v-select__selection {
      color: #000000;
      font-size: 14px !important;
    }
  }
  .title-textarea {
    color: #212121;
    font-size: 10px;
  }
  .v-select__slot {
    input {
      font-size: 16px !important;
    }
    .v-label {
      color: #aaaaaa !important;
      font-size: 14px !important;
      font-weight: 500;
      &--active {
        font-size: 10px !important;
        font-weight: 600;
        color: #000000 !important;
      }
    }
  }
  .text-size-normal * {
    font-size: x-large !important;
  }
}
.multi-select {
  margin: 0;
  padding: 0;
  ::v-deep {
    .v-select__selection--comma {
      font-size: 14px !important;
      color: #424242;
    }
    .v-icon__svg {
      color: #333333;
    }
    .v-messages__message {
      font-size: 12px !important;
    }
  }
}
.combobox-icon {
  ::v-deep {
    .v-icon {
      color: #333333;
      cursor: pointer;
    }
  }
}

.input-pos .v-select__slot {
    input[role="button"] {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      font-size: 14px !important;
      color: #424242;
    }
    .v-input__append-inner {
      i {
        font-size: 24px !important;
      }
    } 
  }

</style>
