<template>
  <div>
    <v-dialog v-model="dialog" @click:outside="closeDialog">
      <v-form ref="form">
        <div class="mb-5">
        <h1>活動履歴登録</h1>
      </div>
      <div class="d-flex justify-space-between">
        <div class="div-left">
          <h4 class="title-field">折衡日</h4>
          <h3 class="mt-2">{{ date }}</h3>
        </div>
        <div class="ml-3 div-right">
          <h4 class="title-field">手段</h4>
          <v-select
              class="text-16px"
              dense
              :items="getEnumClientActionMethodList"
              item-text="name"
              item-value="id"
              v-model="dataActionMethodIdSelected"
            ></v-select>
        </div>
      </div>
      <!-- --- -->
      <div class="d-flex justify-space-between">
        <div class="div-left">
          <h4 class="title-field">相手</h4>
          <v-select
              dense
              :items="getEnumParticipantTypeList"
              v-model="dataParticipantsSelected"
              multiple
              class="text-16px"
              :rules="rules.ruleSelectParticipants"
              required
          ></v-select>
        </div>
        <div class="ml-3 div-right">
         <h4 class="activity-person">担当者</h4>
          <h3 class="text--title my-username" style="color: #000000" v-if="nameStaff">
            {{ nameStaff }}
          </h3>
          <h3 class="text--title my-username" v-else>
            &lt; 自分のユーザー名 >
          </h3>
        </div>
      </div>
      <!-- --- -->
      <div class="d-flex justify-space-between">
        <div class="div-right">
          <h4 class="title-field">対応内容</h4>
          <v-select
              dense
              :items="getEnumClientActionTypeList"
              item-text="name"
              item-value="id"
              v-model="dataClientActionTypeIdSelected"
          ></v-select>
        </div>
      </div>
      <!-- --- -->
      <div class="d-flex justify-space-between">
        <div class="div-left">
          <v-checkbox
              :value="getIsComplaintSelected"
              v-model="dataIsComplaintSelected"
              class="ml-2 text-14px"
              label="クレーム対応"
          ></v-checkbox>
        </div>
        <div class="ml-3 div-right">
          <v-combobox
              label="検討離脱理由"
              :items="getEnumNegotiationExitReasonList"
              v-model="dataReasonForWithdrawal"
          ></v-combobox>
        </div>
      </div>
      <!-- --- -->
      <div>
        <h5 class="title-field">状況</h5>
        <v-textarea
            auto-grow
            v-model="dataRemarksSelected"
            outlined
            rows="10"
            row-height="15"
            class="mt-1"
            :rules="[$rules.checkTextAreaLength()]"
        ></v-textarea>
      </div>
      <!-- --- -->
      <div class="d-flex justify-end">
        <div>
          <v-btn
            style="width: 56px"
            class="t-btn--red-dark mr-4 btn-custom"
            @click="closeDialog"
          >
            閉じる
          </v-btn>
          <v-btn
            style="width: 68px"
            class="t-btn--prm btn-custom"
            @click="save"
          >
            保存
          </v-btn>
        </div>
      </div>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
export default {
  name: 'ActivityHistoryRegistration',
  data() {
    return {
      ReasonForWithdrawal: '検討離脱理由',
      action: '',
      manager: '自分のユーザー名',
      rules: {
        ruleSelectParticipants: [
          v => !!v || 'アイテムが必要です',
          v => !(v && v.length === 0) || 'アイテムが必要です',
        ],
      },
      currentUserLoginName: localStorage.getItem('name')
    };
  },
  props: {
    visible: Boolean,
    date: String,
    actionStaffID: Number,
    nameStaff: String,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
    ...mapGetters([
      // Const Enum
      'getEnumNegotiationExitReasonList',
      'getEnumParticipantTypeList',
      'getEnumClientActionMethodList',
      'getEnumPurchaseRateList',
      'getEnumClientActionTypeList',
      // Data call API
      'getClientActionHistory',
      'getCurrentPurchaseRate',
      // Select default
      'getParticipants',
      'getActionMethodId',
      'getAction',
      // Selected
      'getParticipantsSelected',
      'getActionMethodIdSelected',
      'getIsComplaintSelected',
      'getClientActionTypeIdSelected',
      'getReasonForWithdrawalSelected',
      'getRemarksSelected',
    ]),
    dataActionMethodIdSelected: {
      get() {
        return this.getActionMethodIdSelected;
      },
      set(value) {
        this.setActionMethodIdSelected(value);
      },
    },
    dataParticipantsSelected: {
      get() {
        return this.getParticipantsSelected;
      },
      set(value) {
        this.setParticipantsSelected(value);
      },
    },
    dataClientActionTypeIdSelected: {
      get() {
        return this.getClientActionTypeIdSelected;
      },
      set(value) {
        this.setClientActionTypeIdSelected(value);
      },
    },
    dataIsComplaintSelected: {
      get() {
        return this.getIsComplaintSelected;
      },
      set(value) {
        this.setIsComplaintSelected(value);
      },
    },
    dataRemarksSelected: {
      get() {
        return this.getRemarksSelected;
      },
      set(value) {
        this.setRemarksSelected(value);
      },
    },
    dataReasonForWithdrawal: {
      get() {
        return this.getReasonForWithdrawalSelected;
      },
      set(value) {
        this.setReasonForWithdrawalSelected(value);
      }
    }
  },
  methods: {
    ...mapActions(['actionCreateActivityHistoryRegistration']),
    ...mapMutations([
      // Const Enum
      'setActionMethodIdSelected',
      'setParticipantsSelected',
      'setIsComplaintSelected',
      'setClientActionTypeIdSelected',
      'setRemarksSelected',
      'setReasonForWithdrawalSelected',

      // Alert
      'setAlertSuccess',
      'setAlertError',
    ]),
    save() {
      if (this.$refs.form.validate()) {
        if (this.getParticipantsSelected && this.date) {
          if (this.getParticipantsSelected.length !== 0) {
            const finishData = {
              clientId: this.actionStaffID,
              actionAt: this.date,
              actionMethodId: this.getActionMethodIdSelected,
              participants: this.getParticipantsSelected,
              isComplaint: this.getIsComplaintSelected ? this.getIsComplaintSelected : false,
              exitReason: this.getReasonForWithdrawalSelected ? this.getReasonForWithdrawalSelected : '',
              actionTypeId: this.getClientActionTypeIdSelected,
              remarks: this.getRemarksSelected,
            };
            this.actionCreateActivityHistoryRegistration(finishData).then(() => {
              this.setAlertSuccess('成功した活動履歴の登録');
              this.closeDialog();
            });
          }
        }
      }
    },
    closeDialog() {
      this.setParticipantsSelected(null);
      this.setActionMethodIdSelected(null);
      this.setClientActionTypeIdSelected(null);
      this.setReasonForWithdrawalSelected(null);
      this.setRemarksSelected(null);
      this.setIsComplaintSelected(false);
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
    checkDate(){

    }
  },
};
</script>

<style lang="scss" scoped>
h1{
  color: #212121 !important;
  font-size: 28px !important;
  font-weight: bold !important;
}
h3{
  font-size: 16px !important;
  color: #616161 !important;
  font-weight: 400 !important;
}
h4{
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #212121 !important;
}
h5{
  font-size: 10px !important;
  color: #000000 !important;
  font-weight: 600 !important;
}
.v-btn{
  font-size: 14px !important;
}
::v-deep {
  .v-dialog {
    width: 426px;
    background-color: white;
    border-radius: 9px;
    padding: 16px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .v-select__selections{
    .v-select__selection--comma{
      color: #616161 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }
  .v-autocomplete{
    input{
      color: #616161 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
    .v-label{
      color: #AAAAAA !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      &--active{
        color: #212121 !important;
        font-size: 10px !important;
        font-weight: 600 !important;
      }
    }
  }
  // label
  .title-field {
    color: #000000;
    font-size: 8px;
  }
  // div
  .div-left {
    width: 175px;
  }
  .div-right {
    width: 203px;
  }
  // select and input
  .v-select__selection {
    font-size: 12px !important;
    color: #000000;
  }
  .v-input__icon {
    svg {
      color: #333333;
    }
  }
  .v-input--checkbox {
    .v-label {
      font-size: 14px !important;
      color: #000000 !important;
      font-weight: 500 !important;
    }
  }
  // btn
  .btn-custom {
    height: 32px !important;
    font-size: 14px !important;
  }
}
</style>
